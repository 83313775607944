import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmptyState from '../Component/EmptyState.js';
import Sidebar from '../Component/Sidebar';
import '../CSS/ObituaryManagement.css';
import QRCode from 'react-qr-code';

const ObituaryMForm = () => {
  const [obituaries, setObituaries] = useState([]);
  const [obituaryPicture ,setObituaryPicture] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [qrLink, setQrLink] = useState('');

  const [query, setQuery] = useState('');
  
  useEffect(() => {
    const getObituary = async () => {
      const cookieRes = await fetch(`/api/account/getCookie`)
      const cookie = await cookieRes.json()
      let result;
      if (cookie.role == "SA") {
        result = await fetch(`/api/obituary/getObituaryAdmin`)
      } else {
        result = await fetch(`/api/obituary/getObituary?id=${cookie.id}`)
      }
      const obituaries = await result.json();
      const queryParams = new URLSearchParams(window.location.search);
      const searchParam = queryParams.get('search');
      if (searchParam) {
        const specificData = obituaries.filter((element) => element.name.toLowerCase().includes(searchParam.toLowerCase()));
        if(specificData.length > 0) {
          setObituaries(specificData)
        } else {
          alert("No obituary match this name")
          window.location = "/obituary_manage";
        }
      } else {
        setObituaries(obituaries);
      }
    }
    getObituary();
  }, [])

  const togglePopup = async (picture) => {
    if(picture) {
      const path = await fetch(`/api/obituary/getPic?path=${picture}`);
      setObituaryPicture(path.url)
    }
  };

  const handleQR = async (id) => {
    let link;
    if (id) {
      link = `https://singaporeancestry.com/obituary?id=${id}`;
    }
    if (link.trim() !== '') {
      setQrLink(link);
    } else {
      alert('Please enter a valid link!');
    }
  }

  const handlePublish = async (id) => {
    const response = await fetch(`/api/obituary/updateStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        id: id,
        status: 1,
      }),
    });
    if (response.status == 200) {
      alert("Publish successful!");
      window.location.reload(false);
    } else {
      alert("Publish failed!");
      window.location.reload(false);
    }
  }

  const handleDepublish = async (id) => {
    const response = await fetch(`/api/obituary/updateStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({
        id: id,
        status: 0,
      }),
    });
    if (response.status == 200) {
      alert("Depublish successful!");
      window.location.reload(false);
    } else {
      alert("Depublish failed!");
      window.location.reload(false);
    }
  }

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  
  const handleSearch = () => {
    window.location = `/obituary_manage?search=${query}`;
  };

  const TableContent = () => {
    if(obituaries.length > 0) {
      return (
        <div class="table-body">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet"></link>
          {obituaries.map((obituary) => (
            <li className="table-row" key={obituary.id}>
              <div className="col col-1" data-label="Created Date">
                {obituary.createddate.split('T')[0]}
              </div>
              <div className="col col-2" data-label="Deceased Name">
                {obituary.name}
              </div>
              <div className="col col-3" data-label="中文姓名">
                {obituary.chiname}
              </div>
              <div class="col col-4" data-label="Death Date">
                {obituary.deathdate}
              </div>
              <div class="col col-5" data-label="Age">
                {obituary.age}
              </div>
              <div class="col col-6" data-label="Gender">
                {obituary.gender}
              </div>
              <div className="col col-7" data-label="Status">
                {obituary.status === 1 ? "Published" : "Save as draft"}
              </div>
              <div className="col col-8" data-label="Action">
                {obituary.status === 1 ? (
                  <button onClick={() => handleDepublish(obituary.id)} className="obituarym_depublish_btn"><i class="fas fa-trash icon"></i> Depublish</button>
                ) : (
                  <button onClick={() => handlePublish(obituary.id)} className="obituarym_publish_btn"><i class="fas fa-check icon"></i> Publish</button>
                )}
                <button onClick={() => {togglePopup(obituary.picture);setShowPopup(true);}} className="obituarym_btn"><i class="fas fa-image icon"></i> View</button>
                {showPopup && (
                  <div className="popup-overlay" onClick={() => setShowPopup(false)}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                      <img src={obituaryPicture} alt="Example" className="popup-image"/>
                      <span onClick={() => setShowPopup(false)} className="close-button">✖</span>
                    </div>
                  </div>
                )}
                <button onClick={() => {handleQR(obituary.id);setShowPopup2(true);}} className="obituarym_btn"><i class="fas fa-qrcode qr-icon"></i> QR</button>
                {showPopup2 && (
                  <div className="popup-overlay" onClick={() => setShowPopup2(false)}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                      <h2>QR Code</h2>
                      <QRCode value={qrLink} size={200} bgColor="#ffffff" fgColor="#000000" level="Q"/>
                      <span onClick={() => setShowPopup2(false)} className="close-button">✖</span>
                    </div>
                  </div>
                )}
              </div>
            </li>
          ))}
        </div>
      )
    } else {
      return (
        <EmptyState />
      );
    }
  }
  
  return (
    <div className="obituarym_main-content">
      <div className='obituarym_header-container'>
        <div className='obituarym_title'>
          <h1>Obituary Management</h1>
        </div>
        <div className="obituarym_header">
          <div className='obituarym_side-by-side'>
            <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='obituarym_search-bar' />
            <button onClick={handleSearch} className='obituarym_search-button'>Search</button>
          </div>
          <div className="obituarym_form-actions">
            <Link to="/obituary/create"><button className='obituarym_create-button'>Create Obituary</button></Link>
          </div>
        </div>
      </div>
      <div className='obituarym_body'>
        <ul class="obituarym_responsive-table">
          <li class="table-header">
            <div class="col col-1">Created Date</div>
            <div class="col col-2">Deceased Name</div>
            <div class="col col-3">中文姓名</div>
            <div class="col col-4">Death Date</div>
            <div class="col col-5">Age</div>
            <div class="col col-6">Gender</div>
            <div class="col col-7">Status</div>
            <div class="col col-8">Action</div>
          </li>
          <div class="table-body">
            <TableContent />
          </div>
        </ul>
      </div>
    </div>
  );
};

const ObituaryManagement = () => {
  return (
    <div class="obituarym_page">
      <Sidebar />
      <ObituaryMForm />
    </div>
  );
};

export default ObituaryManagement;
