import React, { useState, useEffect } from 'react';
import '../CSS/ObituaryForm.css';
import Sidebar from '../Component/Sidebar';

const ObituariesForm = () => {
  const [deceasedName, setDeceasedName] = useState('');
  const [CNDeceasedName, setCNDeceasedName] = useState('');
  const [obituaryUploadPro, setObituaryUploadPro] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [deathDate, setDeathDate] = useState('');
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState('');
  const [obituaryStatus, setObituaryStatus] = useState(0);
  const [obituaryUpload, setObituaryUpload] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setObituaryUploadPro(e.target.value)
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
    setObituaryUploadPro('');
  };

  const handleImageUpload2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage2(URL.createObjectURL(file));
      setObituaryUpload(e.target.value)
    }
  };

  const handleDeleteImage2 = () => {
    setImage2(null);
    setObituaryUpload('');
  };

  async function uploadProfilePic(id) {
    try {
      const fileInput = document.getElementById("uploadpropic");
      const file = fileInput.files[0];
      if (!file) {
        alert("No file selected. Please upload a file.");
        return;
      }
      const formData = new FormData();
      formData.append("uploadpropic", file);
      const response = await fetch(`/api/obituary/uploadProfilePic?field=uploadpropic&id=${id}`, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      if (result.status === "success") {
        alert("Obituary Created Successfully");
        window.location = "/obituary_manage";
      } else {
        throw new Error("Failed to upload profile picture");
      }
    } catch (error) {
      alert("Upload profile picture failed... Press 'OK' to refresh");
      window.location.reload(false);
    } finally {
      setIsLoading(false);
    }
  }

  async function upload(id) {
    try{
      const result = await fetch(`/api/account/getCookie`)
      const cookie = await result.json()
      if(cookie.email) {
        const fileInput = document.getElementById("upload");
        const file = fileInput.files[0];
        if (!file) {
          alert("No file selected. Please upload a file.");
          return;
        }
        const formData = new FormData();
        formData.append("upload", file);
        const response = await fetch(`/api/obituary/upload?field=upload&id=${id}`, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();
        if (result.status === "success") {
          await uploadProfilePic(id);
        } else {
          throw new Error("Failed to upload obituary picture");
        }
      }
    } catch(error) {
      alert("Upload obituary picture failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  }

  async function createObituary() {
    try{
      const result = await fetch(`/api/account/getCookie`)
      const cookie = await result.json()
      if(cookie.email) {
        if (!deceasedName || !birthDate || !deathDate || !age || !gender || !obituaryStatus || !obituaryUpload || !obituaryUploadPro) {
          alert("Please enter all the field.");
          return;
        } else {
          const response = await fetch('/api/obituary/create', {
            method: "POST",
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
              name: deceasedName,
              chiname: CNDeceasedName,
              birthdate: birthDate,
              deathdate: deathDate,
              age: age,
              gender: gender,
              status: obituaryStatus,
              createdby: cookie.id
            })
          })
          if (response.status == 200) {
            let test = await response.json();
            if (obituaryUpload) {
              await upload(test.message.insertId);
            }
          } else {
            alert("Obituary creation failed");
            window.location.reload(false);
          }
        }
      }
    } catch(error) {
      alert("CreateObituary failed... Press 'OK' to refresh");
      window.location.reload(false);
    }
  }

  const handleBack = async () => {
    window.location = "/obituary_manage"
  }
  const handleCancel = async () => {
    window.location = "/obituary_manage"
  }
  const handleSave = async () => {
    setIsLoading(true);
    await createObituary();
  };

  return (
    <div className="um_obituary_main-content">
      <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet"></link>
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <p className="spinner-text">Saving, please wait...</p>
        </div>
      )}
      <div className='um_undertaker_header-container'>
        <div className='um_obituary_title'>
          <h1>Create Obituary</h1>
        </div>
        <div className="um_obituary_header">
          <span className="um_obituary_back-button" onClick={handleBack}>← Back</span>
          <div className="um_obituary_form-actions">
            <button className="um_obituary_cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="um_obituary_save-button" onClick={handleSave} disabled={isLoading}>Save</button>
          </div>
        </div>
      </div>
      <div className='um_undertaker_body'>
        <div className='um_obituary_side-by-side'>
          <div className='um_obituary_side_description'>
            <h2>Obituary Details</h2>
            <p>Enter deceased name, death date, age, gender, set status and upload obituary.</p>
          </div>
          <div className="um_obituary_form">
            <div className="um_obituary_form-group">
              <label>Deceased Name<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Deceased Name"
                value={deceasedName}
                onChange={(e) => setDeceasedName(e.target.value)}
              />
            </div>
            <div className="um_obituary_form-group">
              <label>中文姓名</label>
              <input
                type="text"
                placeholder="中文姓名"
                value={CNDeceasedName}
                onChange={(e) => setCNDeceasedName(e.target.value)}
              />
            </div>
            <div className="um_obituary_form-group">
              <label>Upload person picture<span style={{ color: 'red' }}>*</span></label>
              <div className='preview_grid'>
                {image ? (
                  <div className='preview_imageWrapper'>
                    <img src={image} alt="preview" className='preview_image' />
                    <button
                      onClick={handleDeleteImage}
                      className='preview_deleteButton'
                    >
                      <i class="fas fa-trash icon"></i>
                    </button>
                  </div>
                ) : (
                  <label htmlFor="uploadpropic" className="preview_uploadPlaceholder">
                  <span className="upload-icon preview_uploadPlaceholder"><i class="fas fa-file-upload icon"></i></span>
                  <input
                    id="uploadpropic"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {handleImageUpload(e);}}
                    style={{ display: 'none' }}
                  />
                </label>
                )}
              </div>
            </div>
            <div className="um_obituary_form-group">
              <label>Birth Date<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="1940-01-31"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />
            </div>
            <div className="um_obituary_form-group">
              <label>Death Date<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="2024-01-31"
                value={deathDate}
                onChange={(e) => setDeathDate(e.target.value)}
              />
            </div>
            <div className="um_obituary_form-group">
              <label>Age<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Age"
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
            </div>
            <div className="um_obituary_form-group">
              <label>Gender<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              />
            </div>
            <div className="um_obituary_form-group">
              <label>Status of this obituary<span style={{ color: 'red' }}>*</span></label>
              <select onChange={(e) => setObituaryStatus(e.target.value)}>
                <option value="" selected hidden>Select an option</option>
                <option value="0">Save as draft</option>
                <option value="1">Publish</option>
              </select>
            </div>
            <div className="um_obituary_form-group">
              <label>Upload obituary<span style={{ color: 'red' }}>*</span></label>
              <div className='preview_grid'>
                {image2 ? (
                  <div className='preview_imageWrapper'>
                    <img src={image2} alt="preview" className='preview_image' />
                    <button
                      onClick={handleDeleteImage2}
                      className='preview_deleteButton'
                    >
                      <i class="fas fa-trash icon"></i>
                    </button>
                  </div>
                ) : (
                  <label htmlFor="upload" className="preview_uploadPlaceholder">
                  <span className="upload-icon preview_uploadPlaceholder"><i class="fas fa-file-upload icon"></i></span>
                  <input
                    id="upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {handleImageUpload2(e);}}
                    style={{ display: 'none' }}
                  />
                </label>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

// Main Settings Page Component
const ObituaryForm = () => (
  <div className="um_obituary_page">
    <Sidebar />
    <ObituariesForm />
  </div>
);

export default ObituaryForm;
