import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmptyState from '../Component/EmptyState.js';
import Sidebar from '../Component/Sidebar';
import '../CSS/UserManagement.css';

const UserMForm = () => {
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const getAccount = async () => {
      const cookieRes = await fetch(`/api/account/getCookie`)
      const cookie = await cookieRes.json()
      let result;
      if (cookie.role == "SA") {
        result = await fetch(`/api/account/getUserAdmin`)
      } else {
        result = await fetch(`/api/account/getUser?id=${cookie.id}`)
      }
      const accounts = await result.json();
      const queryParams = new URLSearchParams(window.location.search);
      const searchParam = queryParams.get('search');
      if (searchParam) {
        const specificData = accounts.filter((element) => element.username.toLowerCase().includes(searchParam.toLowerCase()));
        if(specificData.length > 0) {
          setUsers(specificData)
        } else {
          alert("No user match this name")
          window.location = "/user_manage";
        }
      } else {
        setUsers(accounts);
      }
    }
    getAccount();
  }, [])

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  
  const handleSearch = () => {
    window.location = `/user_manage?search=${query}`;
  };

  const TableContent = () => {
    const handleActivate = async (email) => {
      const response = await fetch(`/api/account/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: email,
          status: "active",
        }),
      });
      if (response.status == 200) {
        alert("User updated successful!");
        window.location.reload(false);
      } else {
        alert("User updated failed!");
        window.location.reload(false);
      }
    }
  
    const handleDeactive = async (email) => {
      const response = await fetch(`/api/account/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: email,
          status: "inactive",
        }),
      });
      if (response.status == 200) {
        alert("User updated successful!");
        window.location.reload(false);
      } else {
        alert("User updated failed!");
        window.location.reload(false);
      }
    }

    if(users.length > 0) {
      return (
        <div class="table-body">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet"></link>
          {users.map((user) => (
            <li className="table-row" key={user.email}>
              <div className="col col-1" data-label="User Name">
                {user.username}
              </div>
              <div className="col col-2" data-label="Contact No.">
                {user.contactno}
              </div>
              <div className="col col-3" data-label="Email Address">
                {user.email}
              </div>
              <div className="col col-4" data-label="Status">
                {user.status === "active" ? "Active" : "Inactive"}
              </div>
              <div className="col col-5" data-label="Action">
                {user.status === "active" ?
                  (<button onClick={() => handleDeactive(user.email)} className="userm_deactive_btn"><i class="fas fa-trash icon"></i> Deactivate</button>) 
                  : 
                  ( <button onClick={() => handleActivate(user.email)} className="userm_activate_btn"><i class="fas fa-check icon"></i> Activate</button> )}
              </div>
            </li>
          ))}
        </div>
      )
    } else {
      return (
        <EmptyState />
      );
    }
  }
  
  return (
    <div className="userm_main-content">
      <div className='userm_header-container'>
        <div className='userm_title'>
          <h1>User Management</h1>
        </div>
        <div className="userm_header">
          <div className='userm_side-by-side'>
            <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='userm_search-bar' />
            <button onClick={handleSearch} className='userm_search-button'>Search</button>
          </div>
          <div className="userm_form-actions">
            <Link to="/user/create"><button className='userm_create-button'>Create User</button></Link>
          </div>
        </div>
      </div>
      <div className='userm_body'>
        <ul class="userm_responsive-table">
          <li class="table-header">
            <div class="col col-1">User Name</div>
            <div class="col col-2">Contact No.</div>
            <div class="col col-3">Email Address</div>
            <div class="col col-4">Status</div>
            <div class="col col-5">Action</div>
          </li>
          <div class="table-body">
            <TableContent />
          </div>
        </ul>
      </div>
    </div>
  );
};

const UserManagement = () => {
  return (
    <div class="userm_page">
      <Sidebar />
      <UserMForm />
    </div>
  );
};

export default UserManagement;
