import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmptyState from '../Component/EmptyState.js';
import Sidebar from '../Component/Sidebar';
import '../CSS/UndertakerManagement.css';

const UndertakerMForm = () => {
  const [undertakers, setUndertakers] = useState([]);
  const [query, setQuery] = useState('');
  
  useEffect(() => {
    const getAccount = async () => {
      const result = await fetch(`/api/undertaker/getUndertaker`)
      const accounts = await result.json();
      const queryParams = new URLSearchParams(window.location.search);
      const searchParam = queryParams.get('search');
      if (searchParam) {
        const specificData = accounts.filter((element) => element.name.toLowerCase().includes(searchParam.toLowerCase()));
        if(specificData.length > 0) {
          setUndertakers(specificData)
        } else {
          alert("No undertaker match this name")
          window.location = "/undertaker_manage";
        }
      } else {
        setUndertakers(accounts);
      }
    }
    getAccount();
  }, [])

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  
  const handleSearch = () => {
    window.location = `/undertaker_manage?search=${query}`;
  };

  const TableContent = () => {
    const handleActivate = async (email) => {
      const response = await fetch(`/api/account/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: email,
          status: "active",
        }),
      });
      if (response.status == 200) {
        alert("Undertaker updated successful!");
        window.location.reload(false);
      } else {
        alert("Undertaker updated failed!");
        window.location.reload(false);
      }
    }
  
    const handleDeactive = async (email) => {
      const response = await fetch(`/api/account/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          email: email,
          status: "inactive",
        }),
      });
      if (response.status == 200) {
        alert("Undertaker updated successful!");
        window.location.reload(false);
      } else {
        alert("Undertaker updated failed!");
        window.location.reload(false);
      }
    }

    const handlePublish = async (id) => {
      const response = await fetch(`/api/undertaker/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          id: id,
          status: 1,
        }),
      });
      if (response.status == 200) {
        alert("Publish successful!");
        window.location.reload(false);
      } else {
        alert("Publish failed!");
        window.location.reload(false);
      }
    }

    const handleDepublish = async (id) => {
      const response = await fetch(`/api/undertaker/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({
          id: id,
          status: 0,
        }),
      });
      if (response.status == 200) {
        alert("Depublish successful!");
        window.location.reload(false);
      } else {
        alert("Depublish failed!");
        window.location.reload(false);
      }
    }

    if(undertakers.length > 0) {
      return (
        <div class="table-body">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet"></link>
          {undertakers.map((undertaker) => (
            <li className="table-row" key={undertaker.name}>
              <div className="col col-1" data-label="Company Name">
                {undertaker.name}
              </div>
              <div className="col col-2" data-label="PIC Name">
                {undertaker.username}
              </div>
              <div className="col col-3" data-label="Contact No.">
                {undertaker.contactno}
              </div>
              <div class="col col-4" data-label="Email Address">
                {undertaker.email}
              </div>
              <div className="col col-5" data-label="Status">
                {undertaker.astatus === "active" ? "Active" : "Inactive"}
              </div>
              <div className="col col-6" data-label="Action">
                {undertaker.astatus === "active" ? (
                  <button
                    onClick={() => handleDeactive(undertaker.email)}
                    className="undertakerm_deactive_btn"
                  >
                    <i class="fas fa-trash icon"></i> Deactivate
                  </button>
                ) : (
                  <button
                    onClick={() => handleActivate(undertaker.email)}
                    className="undertakerm_activate_btn"
                  >
                    ✔ Activate
                  </button>
                )}
                {undertaker.ustatus === 0 ? (
                  <button
                    onClick={() => handlePublish(undertaker.id)}
                    className="undertakerm_depublish_btn"
                  >
                    <i class="fas fa-trash icon"></i> Depublish
                  </button>
                ) : (
                  <button
                    onClick={() => handleDepublish(undertaker.id)}
                    className="undertakerm_publish_btn"
                  >
                    <i class="fas fa-check icon"></i> Publish
                  </button>
                )}
              </div>
            </li>
          ))}
        </div>
      )
    } else {
      return (
        <EmptyState />
      );
    }
  }
  
  return (
    <div className="undertakerm_main-content">
      <div className='undertakerm_header-container'>
        <div className='undertakerm_title'>
          <h1>Undertaker Management</h1>
        </div>
        <div className="undertakerm_header">
          <div className='undertakerm_side-by-side'>
            <input type="text" value={query} onChange={handleInputChange} placeholder="search" className='undertakerm_search-bar' />
            <button onClick={handleSearch} className='undertakerm_search-button'>Search</button>
          </div>
          <div className="undertakerm_form-actions">
            <Link to="/undertaker/create"><button className='undertakerm_create-button'>Create Undertaker</button></Link>
          </div>
        </div>
      </div>
      <div className='undertakerm_body'>
        <ul class="undertakerm_responsive-table">
          <li class="table-header">
            <div class="col col-1">Company Name</div>
            <div class="col col-2">PIC Name</div>
            <div class="col col-3">Contact No.</div>
            <div class="col col-4">Email Address</div>
            <div class="col col-5">Status</div>
            <div class="col col-6">Action</div>
          </li>
          <div class="table-body">
            <TableContent />
          </div>
        </ul>
      </div>
    </div>
  );
};

const UndertakerManagement = () => {
  return (
    <div class="undertakerm_page">
      <Sidebar />
      <UndertakerMForm />
    </div>
  );
};

export default UndertakerManagement;
