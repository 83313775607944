import React, { useState, useEffect } from 'react';
import '../CSS/UndertakerForm.css';
import Sidebar from '../Component/Sidebar';

const UndertakersForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [addressL1, setAddressL1] = useState('');
  const [addressL2, setAddressL2] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [COContactNo, setCOContactNo] = useState('');
  const [service, setService] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');

  const [PICName, setPICName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [PICContactNo, setPICContactNo] = useState('');

  const [accStatus, setAccStatus] = useState(null);
  const [remarks, setRemarks] = useState('');

  const [hashPassword, setHashPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const triggerCreate = async () => {
      try {
        if(hashPassword !="") {
          const response = await fetch(`/api/account/createAccount`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
              email: emailAddress,
              password: hashPassword,
              role: "Undertaker",
              username: PICName,
              contactno: PICContactNo,
              status: accStatus,
              remarks: remarks,
              createdBy: 1
            }),
          });
          if (response.status == 200) {
            let test = await response.json()
            const response2 = await fetch(`/api/undertaker/create`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
              },
              body: JSON.stringify({
                accountid: test.message.insertId,
                name: companyName,
                address1: addressL1,
                address2: addressL2,
                postcode: postCode,
                city: city,
                state: state,
                country: country,
                contactno: COContactNo,
                service: service,
                website: websiteLink,
                status: 0,
              }),
            });
            if (response2.status == 200) {
              alert("Undertaker created successful!");
              window.location = "/undertaker_manage";
            } else {
              alert("Undertaker creation failed!");
              window.location.reload(false);
            }
          } else {
            alert("Undertaker creation failed!");
            window.location.reload(false);
          }
        }
      } catch (error) {
        alert(`Error: ${error.message}. Press 'OK' to refresh.`);
        window.location.reload(false);
      } finally {
        setIsLoading(false);
      }
    }
    triggerCreate();
  }, [hashPassword])

  const hashPW = async () => {
    try {
      const response = await fetch(`/api/account/hashPW?password=${password}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setHashPassword(data);
    } catch (error) {
      alert("Error fetching hashed password:", error);
    }
  }

  const handleBack = async () => {
    window.location = "/undertaker_manage"
  }
  const handleCancel = async () => {
    window.location = "/undertaker_manage"
  }
  const handleSave = async () => {
    if(emailAddress && password && cPassword && accStatus) {
      if (password !== cPassword) {
        alert("Passwords do not match!");
        return;
      }
      const getAcc = await fetch(`/api/account/specificAccount?email=${emailAddress}`)
      const account = await getAcc.json()
      if(account.length == 0) {
        setIsLoading(true);
        await hashPW();
      } else alert("Email already registered")
    } else alert("Please enter all required fields")
  };

  return (
    <div className="um_undertaker_main-content">
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <p className="spinner-text">Saving, please wait...</p>
        </div>
      )}
      <div className='um_undertaker_header-container'>
        <div className='um_undertaker_title'>
          <h1>Create Undertaker</h1>
        </div>
        <div className="um_undertaker_header">
          <span className="um_undertaker_back-button" onClick={handleBack}>← Back</span>
          <div className="um_undertaker_form-actions">
            <button className="um_undertaker_cancel-button" onClick={handleCancel}>Cancel</button>
            <button className="um_undertaker_save-button" onClick={handleSave} disabled={isLoading}>Save</button>
          </div>
        </div>
      </div>
      <div className='um_undertaker_body'>
        <div className='um_undertaker_side-by-side'>
          <div className='um_undertaker_side_description'>
            <h2>Company Details</h2>
            <p>Enter company name, address and contact information.</p>
          </div>
          <div className="um_undertaker_form">
            <div className="um_undertaker_form-group">
              <label>Company Name</label>
              <input
                type="text"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Address</label>
              <input
                type="text"
                placeholder="Line 1"
                value={addressL1}
                onChange={(e) => setAddressL1(e.target.value)}
              />
              <input
                type="text"
                placeholder="Line 2"
                value={addressL2}
                onChange={(e) => setAddressL2(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Post Code</label>
              <input
                type="text"
                placeholder="Postcode"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>City</label>
              <input
                type="text"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>State</label>
              <input
                type="text"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Country</label>
              <input
                type="text"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Contact No.</label>
              <input
                type="text"
                placeholder="Contact No."
                value={COContactNo}
                onChange={(e) => setCOContactNo(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Service Provided</label>
              <textarea
                placeholder="Service Provided"
                value={service}
                title="Service Provided"
                rows="4"
                cols="30"
                maxlength="255"
                onChange={(e) => setService(e.target.value)}
                className="textarea-service"
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Website Link</label>
              <input
                type="text"
                placeholder="Website Link"
                value={websiteLink}
                onChange={(e) => setWebsiteLink(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='um_undertaker_side-by-side'>
          <div className='um_undertaker_side_description'>
            <h2>PIC Details</h2>
            <p>Enter PIC name, address and contact information.</p>
          </div>
          <div className="um_undertaker_form">
            <div className="um_undertaker_form-group">
              <label>Name</label>
              <input
                type="text"
                placeholder="Name"
                value={PICName}
                onChange={(e) => setPICName(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Email Address<span style={{ color: 'red' }}>*</span></label>
              <input
                type="text"
                placeholder="Email Address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Password<span style={{ color: 'red' }}>*</span></label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Confirm Password<span style={{ color: 'red' }}>*</span></label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
              />
            </div>
            <div className="um_undertaker_form-group">
              <label>Contact No.</label>
              <input
                type="text"
                placeholder="Contact No."
                value={PICContactNo}
                onChange={(e) => setPICContactNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='um_undertaker_side-by-side'>
          <div className='um_undertaker_side_description'>
            <h2>Account Status</h2>
            <p>Activate or suspend this particular accounts.</p>
          </div>
          <div className="um_undertaker_form">
            <div className="um_undertaker_form-group">
              <label>Status of this account<span style={{ color: 'red' }}>*</span></label>
              <select onChange={(e) => setAccStatus(e.target.value)}>
                <option value="" selected hidden>Select an option</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="um_undertaker_form-group">
              <label>Remarks</label>
              <input
                type="text"
                placeholder="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Settings Page Component
const UndertakerForm = () => (
  <div className="um_undertaker_page">
    <Sidebar />
    <UndertakersForm />
  </div>
);

export default UndertakerForm;
